import { Platform, Dimensions, I18nManager } from 'react-native';
const { height, width } = Dimensions.get('window');
export const isRTL = I18nManager.isRTL;
export const isAndroid = Platform.OS === 'android';
export const isIos = Platform.OS === 'ios';
export const screenWidth = width;
export const screenHeight = height;
export const screenAspectRatio = screenWidth < screenHeight ? screenHeight / screenWidth : screenWidth / screenHeight;
export const isTablet = Platform.isPad || (screenAspectRatio < 1.6 && Math.max(screenWidth, screenHeight) >= 900);
export const todayString = 'today';
export var UpdateSources;
(function (UpdateSources) {
    UpdateSources["CALENDAR_INIT"] = "calendarInit";
    UpdateSources["TODAY_PRESS"] = "todayPress";
    UpdateSources["LIST_DRAG"] = "listDrag";
    UpdateSources["DAY_PRESS"] = "dayPress";
    UpdateSources["PAGE_SCROLL"] = "pageScroll";
    UpdateSources["WEEK_SCROLL"] = "weekScroll";
    UpdateSources["PROP_UPDATE"] = "propUpdate";
})(UpdateSources || (UpdateSources = {}));
